@use "./../../../scss/settings/s-colors.scss" as colors;

.s-estate-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  will-change: transform;

  &.--active {
    transform: translateY(0%);

    @include media-breakpoint-down(md) {
      transform: translateY(-84px);
    }
  }
}

.s-estate-bar__container {
  background-color: colors.$gray-100;
  padding-inline: 24px;
  border-radius: 8px 8px 0 0;

  @include media-breakpoint-down(lg) {
    padding: 16px;
  }
}

.s-estate-bar__content {

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.s-estate-bar__flow {
  align-items: center;

  @include media-breakpoint-up(lg) {
    min-height: 120px;
    padding-block: 20px;
  }
}

.s-estate-bar__person,
.s-estate-bar__socials {

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
