@use "./../../../scss/settings/s-colors.scss" as colors;

.i-real-estate-agency__wrapper {
    display: grid;
    @include media-breakpoint-up(lg) {
        gap: 40px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    }
    align-items: center;
    .i-real-estate-agency__image {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @include media-breakpoint-down(lg) {
            height: 148px;
            align-items: center;
        }

        img {
            margin: 0 auto;
        }
    }
    .i-real-estate-agency__body {
        @include media-breakpoint-up(lg) {
            display: grid;
            grid-template-columns: minmax(0, 238fr) minmax(0, 336fr);
            gap: 40px;
            .g-statistical {
                order: -1;
                grid-row: 1 / span 2;
            }
        }

        @include media-breakpoint-down(lg) {
            .g-statistical {
                margin-bottom: 24px;
            }
        }
    }
}



.i-real-estate-agency {
    padding: 16px 24px 32px;
    box-shadow: 0 4px 16px colors.$gray-200;
    border-radius: 10px;

    @include media-breakpoint-down(lg) {
        padding: 16px;
    }
}

.i-real-estate-agency__title {
    margin-bottom: 8px;

    .link {
        font-weight: 700;
    }

    a {
        font-size: inherit;
    }
}

.i-real-estate-agency__rating {
    margin-bottom: 24px;
}

.i-real-estate-agency__description {
    margin-top: auto;

    @include media-breakpoint-down(lg) {
        padding-top: 0;
    }
}

.i-real-estate-agency__perex {
    @include media-breakpoint-down(lg) {
        margin-bottom: 24px;
    }
    line-height: 1.4em;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 5.6em;
    overflow: hidden;
    text-overflow: ellipsis;
}
