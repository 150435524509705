@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-fonts.scss" as fonts;

@use "./../../scss/settings/s-general.scss" as general;

$borderRadius: general.$border-base;

$paddingY: 10px;
$paddingX: 22px;

$paddingLargeY: 18px;
$paddingLargeX: 46px;

$paddingSmallY: 6px;
$paddingSmallX: 16px;

$fontSize: 16px;
$fontFamily: fonts.$primary;
$fontColor: colors.$white;
$fontWeight: 600;

$borderColor: colors.$conversion-600;
$borderColorOutline: colors.$primary;
$borderWidth: 2px;

.btn__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    transition: transform 0.3s ease;
  }
}

.btn {
  user-select: none;
  position: relative;
  transition: background-color 0.3s ease-out;
  transition-property: background-color;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: $fontSize;
  font-family: $fontFamily;
  color: $fontColor;
  font-weight: $fontWeight;
  border-radius: $borderRadius;
  padding: $paddingY $paddingX;
  z-index: 1;
  column-gap: 16px;
  text-decoration: none;
  touch-action: manipulation;
  cursor: pointer;
  border: none;
  min-height: 32px;
  justify-content: center;

  &:hover {
    text-decoration: none;
    color: colors.$white;
  }

  &:focus,
  &:active {
    outline: 0;
    color: colors.$white;
    text-decoration: none;
  }

  &.--primary {
    background-color: colors.$primary;

    .btn__icon {

      path {
        stroke: $fontColor;
      }
    }

    &:hover {
      color: $fontColor;
      background-color: colors.$primary-300;
    }
  }

  &.--conversion {
    background-color: colors.$conversion;
    background-image: colors.$conversion-gradient;
    transition: all 0.3s ease;
    overflow: hidden;
    border: $borderWidth solid $borderColor;

    .btn__icon {

      path {
        stroke: $fontColor;

        // fill: $fontColor;
      }
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      height: 100%;
      background-color: colors.$conversion-600;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.3s ease;
      border-radius: $borderRadius;
      pointer-events: none;
    }

    &:hover {
      color: $fontColor;

      &::after {
        opacity: 1;
      }
    }

    &.--disabled,
    &[disabled] {
      border-color: colors.$gray-200;
      background-image: colors.$conversion-disabled-gradient;

      &::after {
        content: unset;
      }

      &:hover {
        filter: brightness(0.95);
        border-color: colors.$gray-200;
        background-image: colors.$conversion-disabled-gradient;
      }
    }
  }

  &.--outline {
    background: colors.$gray-100;
    color: colors.$primary;

    //border: $borderWidth solid $borderColorOutline;
    border: 1px solid $borderColorOutline;

    &:hover {
      background-color: colors.$primary-100;
    }

    .btn__icon {

      path {
        stroke: colors.$primary;
      }
    }

    &.--disabled,
    &[disabled] {
      color: colors.$gray-300;
      border-color: colors.$gray-200;

      &:hover {
        background-color: colors.$gray-150;
      }
    }
  }

  &.--disabled,
  &[disabled] {
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
  }

  &.--white {
    background-color: colors.$white;
    color: colors.$primary;

    .btn__icon {

      path {
        fill: colors.$primary;
      }
    }

    &:hover {
      background-color: colors.$primary-100;
    }
  }

  &.--outline-white {
    background: transparent;
    color: colors.$white;
    border: $borderWidth solid colors.$white;

    &:hover {
      background-color: colors.$primary-600;
    }

    .btn__icon {

      path,
      circle {
        stroke: colors.$white;
      }
    }
  }

  &.--small {
    padding: $paddingSmallY $paddingSmallX;
    font-size: 13px;
    line-height: 16px;
  }

  &.--icon-before {

    .btn__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .btn__title {
      display: none;
    }

    .btn__icon {

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.--animation-right {

    &:hover {

      svg {
        transform: translateX(8px);
      }
    }
  }

  &.--animation-zoom {

    &:hover {

      svg {
        transform: scale(1.1);
      }
    }
  }

  &.--large {
    padding: $paddingLargeY $paddingLargeX;
    font-size: 21px;
  }

  &.--xs {
    padding: 2px 8px;
    min-height: 32px;
    font-size: 12px;
    gap: 8px;
  }

  &.--no-border {
    border: none;
  }

  &.--position-right {
    display: table;
    margin-left: auto;
    margin-right: 0;
  }

  &.--position-center {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  &.--full-width-mobile {

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &.--full-width {
    width: 100%;

    .btn__body { /* stylelint-disable-line no-descending-specificity */
      min-width: auto;
      padding: 0;
    }
  }
}
